import { moneyFormat, numbFormat } from "./modules-custom/functions";
import { toggleLoader } from "./modules-custom/functions";

var calcSettings;

var intersectionObserver = new IntersectionObserver(async (entries) => {
	if (entries[0].intersectionRatio <= 0) return;
	let response = await fetch(theme.api.endpoint + "w8/v1/auctions");
	let result = await response.json();
	calcSettings = result;
});
// начать наблюдение
if (document.querySelector("[data-calc]")) {
	intersectionObserver.observe(document.querySelector("[data-calc]"));
}

const changeAuctionsOptions = (event) => {
	const auction = document.querySelector("select[name=auction]");
	const auctionCity = document.querySelector("select[name=auctionCity]");
	const costOfTheCar = document.querySelector("input[name=costOfTheCar]");
	if (!auctionCity || !auction) return;

	if (!calcSettings) {
		setTimeout(changeAuctionsOptions, 1000);
		return;
	}

	event.target.removeEventListener("input", changeCostOfTheCar);
	const deafaultOption = auctionCity.querySelector("option");
	auctionCity.innerHTML = "";
	auctionCity.tomselect.clear();
	auctionCity.tomselect.clearOptions();
	auctionCity.append(deafaultOption);

	const auctionData = calcSettings.auctions.find(
		(el) => el.name == auction.value
	);

	auctionData.price.forEach((element) => {
		let option = document.createElement("option");
		option.setAttribute("value", element.price);
		option.dataset.port = element.port;
		option.innerHTML = element.city;
		option.value = element.city;
		auctionCity.insertAdjacentElement("beforeend", option);
	});
	auctionCity.removeAttribute("disabled");
	auctionCity.closest(".input").classList.remove("disabled");

	costOfTheCar.removeAttribute("disabled");
	costOfTheCar.closest(".input").classList.remove("disabled");

	if (auctionCity.tomselect) {
		auctionCity.tomselect.sync();
		auctionCity.tomselect.enable();
	}
};

const changeAuctionCity = () => {
	const auctionCity = document.querySelector("select[name=auctionCity]");
	const portFrom = document.querySelector("input[name=portFrom]");
	// const portTo = document.querySelector('select[name=portTo]');
	const vehicleType = document.querySelector("select[name=vehicleType]");

	if (!portFrom || !auctionCity) return;
	// if (!portFrom || !auctionCity || !portTo) return;

	if (!calcSettings) {
		setTimeout(changeAuctionCity, 1000);
		return;
	}

	const portCurrent =
		auctionCity.options[auctionCity.selectedIndex].dataset.port;

	portFrom.value = portCurrent;
	// Array.prototype.forEach.call(portTo.options, (option) => {
	// 	if (!option.value) {
	// 		return;
	// 	}
	// 	const portData = calcSettings.seaShippingPrice.find((el) => el.port == option.value);
	// 	if (portData.port === '0') {
	// 		option.setAttribute('disabled', 'true');
	// 	} else {
	// 		option.removeAttribute('disabled');
	// 	}
	// });

	// portTo.removeAttribute('disabled');
	// portTo.closest('.input').classList.remove('disabled');
	// if (portTo.tomselect) {
	// 	portTo.tomselect.sync();
	// 	portTo.tomselect.enable();
	// }
	vehicleType.removeAttribute("disabled");
	vehicleType.closest(".input").classList.remove("disabled");
	if (vehicleType.tomselect) {
		vehicleType.tomselect.sync();
		vehicleType.tomselect.enable();
	}
};

const changeCostOfTheCar = (event) => {
	const select = document.querySelector("select[name=auction]");
	select.removeAttribute("disabled");
	select.closest(".input").classList.remove("disabled");
	if (select.tomselect) {
		select.tomselect.enable();
	}
	event.target.removeEventListener("input", changeCostOfTheCar);
};

const checkFieldsCalcAndWrite = async (element, array, target) => {
	if (array.find((el) => !el.validity.valid)) {
		return;
	}

	const loadingElement = element.closest("[data-calc]");
	toggleLoader(loadingElement);

	const formData = new FormData(loadingElement);
	formData.append("wpnonce", theme.api.nonce);
	formData.set("costOfTheCar", moneyFormat.from(formData.get("costOfTheCar")));
	formData.set("engineVolume", numbFormat.from(formData.get("engineVolume")));
	const headers = new Headers();
	headers.append("Content-Type", "application/x-www-form-urlencoded");

	const data = new URLSearchParams(formData);

	let response = await fetch(
		theme.api.ajax_url + "?action=shipp_and_clearence",
		{
			method: "POST",
			headers: headers,
			body: data,
		}
	);

	let result = await response.json();
	if (result.success) {
		const total = result.data.payments_by.type.total;
		const payments = result.data.payments_by.type.subpayments;
		// Вартість авто на аукціоні:
		document.querySelector("[data-calc-total-auction]").innerHTML =
			moneyFormat.to(payments.auction.value);

		// Вартість доставки:
		document.querySelector("[data-calc-total-shipping]").innerHTML =
			moneyFormat.to(payments.shipping.value);

		// Вартість розмитнення:
		document.querySelector("[data-calc-total-clearence]").innerHTML =
			moneyFormat.to(payments.clearence.value);

		// Вартість авто + доставка
		document.querySelector("[data-calc-total]").innerHTML =
			moneyFormat.to(total);
	} else {
		// console.log("НЕ Пішло");
	}
	toggleLoader(loadingElement);

	// document.querySelector('[data-calc-total-shipping]').innerHTML = moneyFormat.to(
	// 	shippingTotal - array.find((el) => el.name === 'costOfTheCar').value
	// );
};

document
	.querySelector("input[name=costOfTheCar]")
	?.addEventListener("input", changeCostOfTheCar);

document
	.querySelector("select[name=auction]")
	?.addEventListener("input", changeAuctionsOptions);

document
	.querySelector("select[name=auctionCity]")
	?.addEventListener("input", changeAuctionCity);

document
	.querySelector("select[name=engineType]")
	?.addEventListener("input", changeEngineType);

function changeEngineType(event) {
	const selectEngineVolume = document.querySelector("[name=engineVolume]");
	const selectGraduationYear = document.querySelector("[name=graduationYear]");
	const placeholderElectro = selectEngineVolume.dataset.labelElectro;
	const placeholderDefault = selectEngineVolume.dataset.labelDefault;

	selectEngineVolume.removeAttribute("disabled");
	selectEngineVolume.closest(".input").classList.remove("disabled");

	selectGraduationYear.removeAttribute("disabled");
	selectGraduationYear.closest(".input").classList.remove("disabled");

	if (selectGraduationYear.tomselect) {
		selectGraduationYear.tomselect.enable();
	}
	
	if (event.target.value == "electric") {
		selectEngineVolume.previousSibling.previousSibling.innerHTML = placeholderElectro;
	} else {
		selectEngineVolume.previousSibling.previousSibling.innerHTML = placeholderDefault;
	}
}

document.querySelectorAll("[data-calc-ids-needs]")?.forEach((el) => {
	const elementsIds = el.dataset.calcIdsNeeds.split(",");
	let elements = elementsIds.map((id) => document.getElementById(id));

	elements.forEach((element, index, array) => {
		element.addEventListener("input", () => {
			checkFieldsCalcAndWrite(element, array, el);
		});
	});
});

document.querySelector("[data-calc]")?.addEventListener("submit", async (e) => {
	e.preventDefault();

	const loadingElement = e.target.querySelector('[type="submit"]');
	const formData = new FormData(e.target);
	formData.append("wpnonce", theme.api.nonce);
	formData.append("page_id", theme.page.id);
	formData.set("costOfTheCar", moneyFormat.from(formData.get("costOfTheCar")));
	formData.set("engineVolume", numbFormat.from(formData.get("engineVolume")));
	const sel = document.getElementById("auctionCity");
	formData.set("auctionCity", sel.options[sel.selectedIndex].text);
	const data = new URLSearchParams(formData);
	const headers = new Headers();
	headers.append("Content-Type", "application/x-www-form-urlencoded");

	toggleLoader(loadingElement);
	toggleLoader(e.target);

	let response = await fetch(theme.api.ajax_url + "?action=add_calculation", {
		method: "POST",
		headers: headers,
		body: data,
	});

	let result = await response.json();

	if (result.success) {
		window.location.href = result.data;
		// console.log("Пішло");
	} else {
		// console.log("НЕ Пішло");
	}
	toggleLoader(loadingElement);
	toggleLoader(e.target);
});
